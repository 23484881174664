<style scoped lang="less">
  .resume-index {
    display: flex;
    padding: 20px;
    box-sizing: border-box;
  }
  .list {
    width: 250px;
    position: relative;
  }
  .right {
    flex: 1;
    margin-left: 20px;
    position: relative;
  }
  .worker-list {
    background-color: #FFF;
    position: relative;
    padding: 8px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-content: flex-start;
    flex-wrap: wrap;
    overflow: auto;
    height: 100%;
    .worker-item {
      width: 33.3333%;
      padding: 8px;
      box-sizing: border-box;
    }
  }
</style>

<template>
  <div class="resume-index">
    <div class="list">
      <r-card style="height: 100%;" flex>
        <span slot="title">全部组织</span>
        <items @choose="orgChoose" :data="orgList" placeholder="请输入机构名称" />
      </r-card>
    </div>
    <div class="right">
      <r-card style="height: 100%;" flex>
        <span slot="title">{{chooseOrg ? chooseOrg.label : '全部组织'}}</span>
        <fm-input-new v-model="workerKey" clearable style="margin-right: 10px;" placeholder="员工搜索" slot="action">
          <i class="fmico fmico-search" slot="prefix"></i>
        </fm-input-new>
        <div class="worker-list">
          <div class="worker-item" v-for="worker in workerShowList" :key="worker.id">
            <worker style="cursor: pointer;" :data="worker" @click.native="itemClick(worker)" />
          </div>
        </div>
      </r-card>
    </div>
  </div>
</template>

<script>
import RCard from './components/card'
import Items from './components/items'
import Worker from './components/worker'

import { getWorkerList } from './libs'

import { orgRequest } from '@/api'

import { workerRequest } from '../../api'

export default {
  components: { RCard, Items, Worker },
  data () {
    return {
      chooseOrg: null,
      workerKey: null,
      workerList: [],
      orgTree: [],
      // treeIdList: []
    }
  },
  computed: {
    orgList () {
      return [
        { label: '全部组织', key: null, data: null },
        ...this.$store.getters.orgList
      ]
    },
    workerShowList () {
      let userIds = this.chooseOrg && this.chooseOrg.data ? (this.chooseOrg.data.userList || []).map(v => v.id) : null
      if (this.workerKey || userIds) {
        return this.workerList.filter(v => {
          let pass = true
          if (this.workerKey && v.name.indexOf(this.workerKey) == -1) {
            pass = false
          }
          if (pass && userIds) {
            pass = userIds.includes(v.userId)
          }
          return pass
        })
      }
      return this.workerList
    }
  },
  methods: {
    itemClick (worker) {
      this.$router.push({
        name: 'hrms.resume.details.base',
        query: { id: worker.id }
      })
    },
    orgChoose (org) {
      this.chooseOrg = org
      // if (org.key) {
      //   const tree = treeFind(this.orgTree, (v) => v.id === org.key)
      //   this.treeIdList = treeFlat([tree]).map(v => v.positions || []).flat().map(v => v.id)
      // } else {
      //   this.treeIdList = []
      // }
      this.workerKey = null
    },
    async loadWorker () {
      let data = []
      const isAdmin = this.$store.getters.currentRoleId === 1
      if (isAdmin || this.$store.getters.currentFunKeys.includes('hrms.person.index-getAllResume')) {
        data = await workerRequest.get()
      } else if (this.$store.getters.currentFunKeys.includes('hrms.person.index-getMyOrgContainChildResume')) {
        data = await workerRequest.getMyOrgContainChild()
      } else if (this.$store.getters.currentFunKeys.includes('hrms.person.index-getMyOrgResume')) {
        data = await workerRequest.getMyOrg()
      } else if (this.$store.getters.currentFunKeys.includes('hrms.person.index-getMyResume')) {
        data = await workerRequest.getMy()
      }
      data.otherInfo = data.eduHis ? JSON.parse(data.eduHis) : {}
      return data
    },
    async loadWorkerList () {
      this.workerList = await getWorkerList(this.loadWorker, true)
    },
    async loadOrgTree () {
      this.orgTree = await orgRequest.getTree()
    }
  },
  async mounted () {
    this.loadOrgTree()
    this.loadWorkerList()
    this.$store.dispatch('loadOrgList')
  }
}
</script>
